import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import StartPage from './components/StartPage';
import QuestionPage from './components/QuestionPage';
import ResultPage from './components/ResultPage';

const App = () => {
  // State to store the user's answers (initially all null)
  const [answers, setAnswers] = useState(Array(10).fill(null));

  // Function to reset the answers when restarting the quiz
  const resetQuiz = () => {
    setAnswers(Array(10).fill(null));  // Reset answers to null for all questions
  };

  // Update the answer for a particular question
  const handleAnswerChange = (questionIndex, answer) => {
    const newAnswers = [...answers];
    newAnswers[questionIndex] = answer;
    setAnswers(newAnswers);
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<StartPage />} />
        {Array.from({ length: 10 }, (_, i) => (
          <Route
            key={i}
            path={`/question/${i + 1}`}
            element={
              <QuestionPage
                questionIndex={i}
                answer={answers[i]}            // Pass the current answer for the question (initially null)
                onAnswerChange={handleAnswerChange}  // Update the answer for the specific question
              />
            }
          />
        ))}
        <Route path="/result" element={<ResultPage answers={answers} onReset={resetQuiz} />} />
      </Routes>
    </Router>
  );
};

export default App;
