import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ResultPage.css';

// Role descriptions
const roleDescriptions = {
  Leader: "Leaders can emerge in various settings from business to politics to community groups and sports teams. Effective leadership often involves balancing task oriented and people oriented skills",
  Collaborator: "A collaborator is someone who actively collaborates with others to achieve a common goal. Collaborators are essential for the success of teams, organizations, and community initiatives.",
  Doer: "A doer is action-oriented and driven to execute tasks and achieve tangible results. Doers are known for their proactive approach and their ability to get things done efficiently.",
  Strategist: "A strategist is someone who excels in formulating and implementing plans that are designed to achieve specific long-term or overall goals.",
  Facilitator: "A facilitator is a professional skilled in guiding groups and individuals through processes to achieve specific objectives, such as generating ideas, resolving conflicts, improving teamwork, or conducting effective meetings and workshops."
};

// Map role indices to roles
const roles = ['Leader', 'Collaborator', 'Doer', 'Strategist', 'Facilitator'];

// Function to calculate the result based on the user's answers
const calculateResult = (answers) => {
  let scores = {
    Leader: 0,
    Collaborator: 0,
    Doer: 0,
    Strategist: 0,
    Facilitator: 0,
  };

  // Points based on answers
  const scoreMap = {
    1: -2,  // Strongly Disagree
    2: -1,  // Disagree
    3: 0,   // Neutral
    4: 1,   // Agree
    5: 2    // Strongly Agree
  };

  // Roles assigned to specific questions
  const roleMapping = [null, 'Facilitator', 'Collaborator', null, 'Leader', null, 'Strategist', null, 'Doer', null];

  // Update scores based on user answers
  answers.forEach((answer, index) => {
    const role = roleMapping[index];
    if (role) {
      scores[role] += scoreMap[answer];
    }
  });

  // Find the role with the highest score
  const maxScore = Math.max(...Object.values(scores));
  const topRoles = Object.keys(scores).filter(role => scores[role] === maxScore);

  // Return a random role if there's a tie
  return topRoles[Math.floor(Math.random() * topRoles.length)];
};

const ResultPage = ({ answers, onReset }) => {
  const navigate = useNavigate();
  const [result, setResult] = useState(null);

  useEffect(() => {
    const calculatedRole = calculateResult(answers);
    setResult(calculatedRole);
  }, [answers]);

  // Handle returning to the home page
  const handleReturn = () => {
    onReset();  // Reset the quiz state (answers)
    navigate('/');  // Navigate back to the home page
  };

  // Handle sharing the result using the Web Share API
  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Quiz Result',
        text: `I got the role: ${result}! Find out what role you are!`,
        url: window.location.href
      }).catch(err => console.error('Error sharing:', err));
    } else {
      alert('Sharing not supported on this browser.');
    }
  };

  if (!result) {
    return <div>Loading...</div>;
  }

  return (
    <div className="result-page-container">
      <div className="result-page-witd">
        <h1 class="quiz-tit-result">Quiz Result</h1>
        <img className="result-team-img"
            src={`${process.env.PUBLIC_URL}/mangementresult.png`}  // Use template literal inside JSX expression
            alt="Project Management"
            style={{ width: '150px', height: 'auto', margin: '20px auto' }}/>
        <h1 class="result-heading">You Are:The {result}</h1>
        <div className="dev-result">
        <p className="result-text">{roleDescriptions[result]}</p>
        </div>
      
      {/* Return Button */}
      <button onClick={handleReturn} className="return-button">
        Return to Home
      </button>
      
      {/* Share Button */}
      {/* <button onClick={handleShare} className="share-button">
        Share your result
      </button> */}
      </div>
    </div>
  );
};

export default ResultPage;
