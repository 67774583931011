import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './QuestionPage.css'; // Import CSS for styling

const QuestionPage = ({ questionIndex, answer, onAnswerChange }) => {
  const navigate = useNavigate();
  const [selectedAnswer, setSelectedAnswer] = useState(null);  // Initially, no answer is selected
  const [animate, setAnimate] = useState(false); // New state for animation control

  // List of questions
  const questions = [
    "When starting a project, do you prefer to organize tasks?",           
    "Do you know how to handle team conflicts during a project?",          
    "Do you prefer to work alone on tasks or as part of a team?",          
    "Does high pressure motivate and stimulate you?",                      
    "Do you feel comfortable leading meetings and setting agendas?",       
    "Do you prefer to collaborate with stakeholders during a project?",    
    "Do you constantly revise possible unforeseen risks in a project?",    
    "Do you regularly adjust project plans based on team feedback?",       
    "How comfortable are you with managing multiple tasks at once?",       
    "How comfortable are you with handling changes in project scope?"      
  ];

  // Set the selected answer when the component mounts
  useEffect(() => {
    setSelectedAnswer(answer);  // Restore the selected answer if it exists
    setAnimate(true); // Trigger the animation when the question is loaded
  }, [answer, questionIndex]);

  // Handle answer selection
  const handleOptionChange = (value) => {
    setSelectedAnswer(value);
    onAnswerChange(questionIndex, value);  // Update the global answer state
  };

  // Navigate to next question or result page
  const handleNext = () => {
    if (selectedAnswer !== null) {  // Proceed only if an option is selected
      setAnimate(false); // Reset animation
      onAnswerChange(questionIndex, selectedAnswer);  // Save the current answer
      setTimeout(() => {
        // If last question, go to result page
        if (questionIndex === 9) {
          navigate('/result');
        } else {
          navigate(`/question/${questionIndex + 2}`);  // Go to the next question
        }
      }, 500); // Small delay for smooth transition
    }
  };

  // Navigate to the previous question
  const handlePrevious = () => {
    if (questionIndex > 0) {
      setAnimate(false); // Reset animation
      setTimeout(() => {
        navigate(`/question/${questionIndex}`);  // Go back to the previous question
      }, 100); // Small delay for smooth transition
    }
  };

  // Calculate the progress percentage
  const progressPercentage = ((questionIndex + 1) / 10) * 100;

  return (
    <div className={`quiz-container ${animate ? 'fadeUp' : ''}`}>
      {/* Question Index and Progress Bar */}
      <div className="quiz-header">
        <div className="question-index">
          Question {questionIndex + 1}.
        </div>
        <div className="progress-bar-container" >
          <div className="progress-bar"  
              style={{ width: `${((questionIndex + 1) / 10) * 100}%`,     
              }}>
            </div>
          <div className="progress-text" >
            {questionIndex + 1}/10
          </div>
        </div>
      </div>

      {/* Question */}
      <div className="question-content">
        <div>
          <p>{questions[questionIndex]}</p>
        </div>

        {/* Answer Options */}
        <div className="options">
          <div className="options-col">
            {["Strongly Disagree", "Disagree", "Neutral", "Agree", "Strongly Agree"].map((option, i) => (
              <label key={i} className={`option-label ${selectedAnswer === i + 1 ? 'selected' : ''}`}>
                <input
                  type="radio"
                  value={i + 1}
                  checked={selectedAnswer === i + 1}
                  onChange={() => handleOptionChange(i + 1)} />
                <span className="custom-radio"></span> {/* Custom radio button circle */}
                {option}
              </label>
            ))}
          </div>
        </div>

        <div className="button-container">
          {/* Previous button (disabled for the first question) */}
          {questionIndex > 0 && (
            <button className="previous-button" onClick={handlePrevious}>
              Previous
            </button>
          )}
          
          {/* Next or Submit button */}
          <button 
            className="next-button" 
            onClick={handleNext} 
            disabled={selectedAnswer === null}  // Disable if no answer is selected
          >
            {questionIndex === 9 ? 'Submit' : 'Next'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuestionPage;
