import React from 'react';
import { useNavigate } from 'react-router-dom';
import './StartPage.css'; // Import the CSS file for styling
import '../App.css';
const StartPage = () => {
  const navigate = useNavigate();

  const handleStartClick = () => {
    navigate('/question/1');
  };

  return (
  
    <div className="start-page">
        <h1>Home Page</h1>
      <div className="check">
        <h1 class="quiz-tit">Worker Type Assessment Quiz</h1>
        <img className="start-image"
          src={`${process.env.PUBLIC_URL}/asd.jpeg`}  // Use template literal inside JSX expression
          alt="Project Management"
          style={{ width: '120px', height: 'auto', margin: '20px auto' }}/>
      
        <p className="description">
          This is a questionnaire about personal work styles and preferences designed to understand respondents' behavioral patterns, decision-making approaches and values in the work place.
        </p>
        <button className="start-button" onClick={handleStartClick}>
          Start
        </button>
      </div>
    </div>

  );
};

export default StartPage;
